<template>
  <b-row>
    <b-col cols="12">
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              "
            >
              <label>Показывать по</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>записей</label>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
                />

              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refOrderDocuments"
          class="position-relative"
          :items="fetchItems"
          responsive
          :fields="Columns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Совпадающих записей не найдено"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item :to="{ name: 'sale-admin-view', params: { id:data.item.id}}">
                <feather-icon icon="WatchIcon" />
                <span class="align-middle ml-50">Открыть</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <template #cell(createdUser)="data">
            <b-media
              v-if="data.item.createdUser"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.createdUser.file ? data.item.createdUser.file.path : null"
                  :text="avatarText(`${data.item.createdUser.firstName} ${data.item.createdUser.lastName || ''}`)"
                  :variant="`light-${generateRandomVariant()}`"
                  :to="{ name: 'apps-users-view', params: { id: data.item.createdUser.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-users-view', params: { id: data.item.createdUser.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.createdUser.firstName }} {{ data.item.createdUser.lastName || '' }}
              </b-link>
              <small class="text-muted">{{ data.item.createdUser.phone }}</small>
            </b-media>
          </template>

          <template #cell(updatedUser)="data">
            <b-media
              v-if="data.item.updatedUser"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.updatedUser.file ? data.item.updatedUser.file.path : null"
                  :text="avatarText(`${data.item.updatedUser.firstName} ${data.item.updatedUser.lastName || ''}`)"
                  :variant="`light-${generateRandomVariant()}`"
                  :to="{ name: 'apps-users-view', params: { id: data.item.updatedUser.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-users-view', params: { id: data.item.updatedUser.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.updatedUser.firstName }} {{ data.item.updatedUser.lastName || '' }}
              </b-link>
              <small class="text-muted">{{ data.item.updatedUser.phone }}</small>
            </b-media>
          </template>

          <template #cell(teamId)="data">
            <span
              v-if="data.item.team"
              variant="light-secondary"
            >
              {{ data.item.team.title }}
            </span>
          </template>
          <template #cell(status)="data">
            <b-link
              :to="{ name: 'sale-admin-view', params: { id:data.item.id}}"
              class="font-weight-bold d-block text-nowrap"
            >
              <b-badge :variant="`light-${ resolveUserMotivationStatusVarian(data.item.status.name) }`">
                {{ resolveUserMotivationStatus(data.item.status.title) }}
              </b-badge>
            </b-link>

          </template>

          <template #cell(ticketVerification)="data">
            <div class="text-nowrap">
              <feather-icon
                :icon="data.item.ticketVerification ? 'CheckIcon' : 'SlashIcon'"
                size="18"
                class="mr-50"
                :class="data.item.ticketVerification ? 'text-success' : 'text-danger'"
              />
            </div>
          </template>

          <template #cell(createdAt)="data">
            {{ formatDate(data.item.createdAt) }}
          </template>
          <template #cell(updatedAt)="data">
            {{ formatDate(data.item.updatedAt) }}
          </template>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-start
                "
              >
                <span
                  class="text-muted"
                >Показано с {{ dataMeta.from }} по {{ dataMeta.to }} из
                  {{ dataMeta.of }} элементов</span>
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-end
                "
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Показано с {{ dataMeta.from }} по {{ dataMeta.to }} из {{ dataMeta.of }} элементов</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BTable,
  BCol,
  BCard,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BLink,
  BMedia,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ability from '@/libs/acl/ability'
import { formatDate } from '@/libs/helper'
import {
  generateRandomVariant, resolveUserMotivationStatus, resolveUserMotivationStatusVarian,
} from '@/utils/UserFilter'
import { avatarText } from '@core/utils/filter'
import { elementList } from '@/application/orderService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormInput,
    BPagination,
    vSelect,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BLink,
    BMedia,
  },
  // // eslint-disable-next-line vue/require-prop-types
  // props: ['actionId'],
  methods: {
  },
  setup() {
    const {
      refOrderDocuments,
      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      clearSelectTable,
      clearSelect,
      Columns,
    } = elementList()
    return {
      refOrderDocuments,
      fetchItems,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      clearSelectTable,
      clearSelect,
      generateRandomVariant,
      Columns,
      formatDate,
      avatarText,
      ability,
      BDropdownItem,

      resolveUserMotivationStatus,
      resolveUserMotivationStatusVarian,
    }
  },
}
</script>
